<template>
  <div class="intro-y box w-full">
    <div ref="table" class="relative w-full">
      <loading-spinner
        type="half-circle"
        :duration="1000"
        :size="40"
        color="#000"
        :loading="loading"
      />
      <div class="overflow-x-auto">
        <div
          class="flex flex-col sm:flex-row sm:items-end xl:items-start justify-end"
        >
          <div class="flex mt-5 sm:mt-0">
            <button
              class="btn bg-theme-31 text-white sm:w-auto pb-2 pt-2 mt-3 mr-3 ml-3"
              @click="
                $router.push(
                  `/users/${id}/${
                    type === 'owners' ? 'assignOwned' : 'assignMembered'
                  }`
                )
              "
            >
              <PlusIcon class="w-5 h-5 mr-2 ml-2" /> {{ i18n('common.add') }}
            </button>
          </div>
        </div>
        <table ref="tabulator" class="table table-report sm:mt-2">
          <thead>
            <tr>
              <th class="whitespace-nowrap">#</th>
              <th
                v-for="col in tableColumns"
                :key="col.name"
                class="text-center whitespace-nowrap"
              >
                {{ i18n(col.label) }}
              </th>
            </tr>
          </thead>
          <tbody v-if="rows.length">
            <tr v-for="(row, index) in rows" :key="index" class="intro-x">
              <td class="w-40">
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  {{ rowIndex(index) }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium whitespace-nowrap">
                  {{ presenter(row, 'code') }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium whitespace-nowrap">
                  {{ presenterLocalization(row.unitTemplate.project, 'name') }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium whitespace-nowrap">
                  {{ presenterLocalization(row.unitTemplate, 'title') }}
                </div>
              </td>
              <td class="table-report__action w-56" id="ignore-2">
                <div
                  class="flex justify-center items-center gap-3 text-theme-24"
                >
                  <router-link
                    class="text-view dark:text-theme-30 flex items-center gap-2 whitespace-nowrap"
                    :to="`/units/${row.id}`"
                  >
                    <EyeIcon class="w-4 h-4" /> {{ i18n('common.view') }}
                  </router-link>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <app-empty-page
          v-if="!rows.length && !loading"
          :label="i18n(`empty.${type}`)"
          label-class="text-lg font-medium"
          icon-size="4x"
          class="box w-full py-64"
        ></app-empty-page>
      </div>
      <Pagination
        v-if="rows.length"
        :page="currentPage"
        :pageSize="pagination.limit"
        :rowsNumber="pagination.total"
        :showSizeChanger="true"
        :disabled="!rows.length"
        position="center"
        @next-page="doNextPage"
        @prev-change="doPrevPage"
        @size-change="doChangePaginationPageSize"
      ></Pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { defineComponent, ref, onMounted } from 'vue'
import feather from 'feather-icons'
import { GenericModel } from '@/shared/model/generic-model'

export default defineComponent({
  name: 'user-list-table',
  props: {
    id: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
  },
  setup() {
    const tabulator = ref()
    const reInitOnResizeWindow = () => {
      // Redraw table onresize
      window.addEventListener('resize', () => {
        // tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    onMounted(() => {
      reInitOnResizeWindow()
    })

    return {
      tabulator
    }
  },
  data() {
    return {
      // filterInput: [],
      columns: [
        {
          name: 'code',
          field: 'code',
          label: 'units.fields.code',
          align: 'center'
        },
        {
          name: 'project',
          field: 'project',
          label: 'units.fields.project',
          align: 'center'
        },
        {
          name: 'unitTemplate',
          field: 'unitTemplate',
          label: 'units.fields.unitTemplate',
          align: 'center'
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      rows: 'unit/list/rows',
      pagination: 'unit/list/pagination',
      loading: 'unit/list/loading',
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode'
    }),
    rowIndex() {
      return (index) => {
        return index + this.pagination.limit * (this.currentPage - 1) + 1
      }
    },
    currentPage() {
      return this.pagination.currentPage || 1
    },
    tableColumns() {
      return this.columns.filter((col) => col.name != this.userType)
    }
  },
  methods: {
    ...mapActions({
      doFetch: 'unit/list/doFetch',
      doFetchNextPage: 'unit/list/doFetchNextPage',
      doFetchPreviousPage: 'unit/list/doFetchPreviousPage',
      doChangePaginationCurrentPage: 'unit/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'unit/list/doChangePaginationPageSize'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    presenterTime(row, fieldName) {
      return GenericModel.presenterTime(row, fieldName)
    },
    presenterInteger(row, fieldName) {
      return GenericModel.presenterInteger(row, fieldName)
    },
    presenterDecimal(row, fieldName) {
      return GenericModel.presenterDecimal(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options
      const val = options.filter((el) => el.id == row[fieldName])
      return val.length ? val[0].label : 'ـــ'
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    },
    async doFetchFirstPage() {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        filter: [
          {
            key: this.type,
            value: this.id,
            operator: 'arrayContains'
          }
        ],
        pagination: {
          firstPage: true,
          limit: 10,
          action: 'next',
          orderBy: 'createdAt',
          sortType: 'desc'
        }
      })
    },
    loggy() {}
  }
})
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
